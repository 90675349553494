.word {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.visible {
    visibility: visible;
    opacity: 1;
}
